@import 'styles/base';

.background-search {
  margin: 0;
  display: flex;
  min-height: 380px;
  align-items: center;
  padding-bottom: 29px;
  flex-direction: column;
  background-size: cover;
  background-position: 25% 0;
  background-blend-mode: overlay;
  background-image: url('/static/images/background_blue.png');
  &.searching {
    min-height: 142px;
    padding: 31px 0 29px 0;
    background-image: url('/static/images/background_blue_small.png');
    .input-custom-content {
      margin: 42px 0 40px 0;
    }
    .button-switch-content {
      margin-top: 42px;
    }
    .search-content button {
      margin: 42px 0 40px 3.5px;
    }
  }
  .title {
    margin: 88px auto 39px;
    max-width: 795px;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    line-height: 37px;
    font-stretch: normal;
    letter-spacing: -0.9px;
    text-align: center;
    color: #ffffff;
  }
  .group-custom {
    margin-bottom: 0;
    padding: 0 3.5px !important;
  }
  .input-custom-content {
    margin: 0;
    height: 60px;
    font-size: 16px;
    color: #919eab;
    padding: 0 20px;
    position: relative;
    border-radius: 3px;
    letter-spacing: -0.3px;
    background-color: #fff;
    border: solid 1px #c4cdd5;
    box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
    label {
      top: 9px;
      margin: 0;
      z-index: 2;
      opacity: 0;
      left: 12px;
      font-size: 11px;
      position: absolute;
      transition: all 0.3s ease;
      transform: translateY(5px);
      letter-spacing: -0.24px;
      font-family: 'Open Sans', sans-serif;
      &.show {
        opacity: 1;
        transform: translateY(0px);
      }
      &.label-right {
        top: 22px;
        right: 10px;
        left: initial;
        display: none;
        cursor: pointer;
        font-size: 14px;
        letter-spacing: -0.3px;
        font-family: 'Open Sans', sans-serif;
        &.show {
          display: block;
        }
      }
      img {
        width: 12px;
        margin: 0 0 0 3px;
      }
    }
    input {
      left: 0;
      top: 0;
      border: none;
      padding-top: 0;
      height: 58px;
      font-size: 22px;
      color: #212b36;
      line-height: 30px;
      position: absolute;
      letter-spacing: -0.34px;
      font-family: 'Open Sans', sans-serif;
      &.custom {
        padding-top: 12px;
        padding-bottom: 0;
      }
      &::placeholder {
        font-size: 16px;
        color: #919eab;
      }
      &.form-control:focus {
        box-shadow: none;
      }
    }
  }
  .button-switch-content {
    max-width: 240px;
    text-align: right;

    label {
      background: transparent;
      border: 1px solid color(sky, dark);
      border-radius: 3px;
      color: color(white);
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 30px;

      &.active:not(:disabled) {
        box-shadow: none;
        background: color(white);
        border: 1px solid color(sky, dark);
        color: color(blue, base);
      }
    }

    .help {
      color: color(white);
      font-size: 12px;

      img {
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }
  .search-content {
    button {
      width: 60px;
      height: 60px;
      margin: 0 3.5px;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      line-height: 30px;
      background-image: linear-gradient(to bottom, #eaa61e, #e6890e);
      span {
        display: none;
        color: #fff;
        font-size: 16px;
        margin-right: 14px;
      }
    }
    .action {
      max-width: 24px;
      max-height: 24px;
    }
  }
  .center {
    display: flex;
    margin: 0 10px;
  }
  .autocomplete {
    margin: 60px 0 0 -21px;
    width: 16vw;
    border-radius: 3px;
    max-height: 50vh;
    overflow-y: scroll;
    & li {
      z-index: 3;
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    .autocomplete {
      max-height: 40vh;
      width: 84vw;
    }
  }

  @media (min-width: 576px) {
    .center {
      justify-content: center;
    }
  }

  @media (min-width: 992px) {
    .search-content {
      padding: 0 !important;
      max-width: 60px;
    }
  }

  @media (max-width: 992px) {
    padding-bottom: 40px;
    .autocomplete {
      width: 78vw;
    }
    .search-content {
      button {
        width: 100%;
        line-height: 22px;
        margin: 6px 0 0 0 !important;
        span {
          display: inline-block;
        }
      }
    }
    .title {
      font-size: 24px;
      margin-top: 30px;
    }
    .input-custom-content {
      margin: 0 0 3px 0 !important;
      input {
        line-height: 22px;
      }
    }
  }
}
