$link-color: #117bc8;
.login-modal {
  input,
  textarea {
    box-sizing: border-box;
    font-size: 15px;
  }
  p {
    font-size: 16px;
    color: $link-color;
    text-align: center;
    cursor: pointer;
  }
  label {
    margin-bottom: 5px;
  }
  .container-button-forgot {
    display: flex;
    justify-content: center;
  }
  .button-link {
    display: flex;
    background-color: transparent;
    border-color: transparent;
    justify-content: center;
    margin-bottom: 15px;
    color: $link-color;
    font-size: 13px;
    &-inline {
      display: inline;
      padding: 0;
      margin: 0;
      height: auto;
      color: $link-color;
      vertical-align: baseline;
    }
  }
}
