.telemedicine-modal {
  &-body {
    height: 33vh;
    max-width: 500px;
    overflow-y: auto;
    text-align: left;
    min-height: 308px;

    ul {
      margin-left: 24px;
      list-style: circle;
    }
  }

  &-image {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  &-trigger {
    cursor: pointer;
    outline: none;
  }
}
