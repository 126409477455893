.search-list-container {
  .background-search {
    padding-top: 35px;
    min-height: 142px;
  }
  .result-content {
    margin: 55px 0 50px 0;
  }
  .empty-content {
    max-width: 326px;
    margin: 150px auto 149px;
    text-align: center;
    img {
      width: 64px;
      height: 64px;
      margin: 0 auto 20px;
    }
    p.bold {
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      margin-bottom: 14px;
      color: #212b36;
    }
    p {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #637381;
    }
  }
  .search-list {
    margin-bottom: 64.9px;
    .container {
      padding: 0 10% 0 10%;
      border-bottom: 1px solid #e8f2f8;
      .description-content {
        padding-left: 26px;
      }
      .row {
        padding: 20px 0 19px 0;
        border-bottom: 1px solid #e5e5e5;
        .image-content {
          max-width: 84px;
          padding: 0;
        }
        .physician {
          max-width: 200px;
          height: 24px;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #212b36;
          cursor: pointer;
          margin: 0;
        }

        .address-content {
          margin-bottom: 2px;

          p {
            margin: 0;
            width: auto;
            font-size: 14px;
            color: #637381;
            line-height: 1.43;
          }
        }
        .subTitle {
          margin: 0;
          font-size: 14px;
          width: fit-content;
          color: #637381;
          line-height: 1.43;
          cursor: pointer;
          margin-bottom: 10px;
        }
        img {
          width: 84px;
          height: 84px;
          cursor: pointer;
          border-radius: 50%;
          object-fit: cover;
        }
        .info-doctor {
          margin-bottom: 10px;
          img {
            width: 16px;
            height: 16px;
            margin: 0 3px 3px 0;
          }
        }
        .button-container {
          padding: 0;
          display: flex;
          min-width: 39%;
          align-items: center;
          justify-content: flex-end;
          button {
            max-width: 235px;
            margin: 15px 0;
          }
        }
      }
    }
    .pagination {
      display: flex;
      margin: 27px 0;
      align-items: center;
      p {
        margin: 0 0 0 25px;
        font-size: 12px;
        color: #919eab;
      }
      button {
        padding: 0;
        width: 38px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        border: solid 1px #c4cdd5;
        box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
        background-image: linear-gradient(to bottom, #ffffff, #f9fafb);
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .search-list .container {
    padding: 0 20px !important;
  }
  .pagination {
    margin: 27px 20px;
  }
  .button-container,
  .button-container button {
    max-width: initial !important;
  }
}
@media (max-width: 1200px) {
  .button-container {
    min-width: 38% !important;
  }
  .button-container,
  .button-container button {
    max-width: initial !important;
  }

  .title-content {
    margin: 0 10px 0 10px;
  }
  .row {
    padding: 20px 20px 19px 20px !important;
  }
}
