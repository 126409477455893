.links-button {
  padding-bottom: 82px;
  .line {
    display: flex;
    justify-content: center;
  }
  .section {
    margin-bottom: 37px;
  }
  h2 {
    font-size: 16px;
    font-weight: 600;
    color: #212b36;
    margin: 0 0 17px 0;
    letter-spacing: -0.36px;
    line-height: 1.5;
  }
  .button-link {
    padding: 0;
    height: 20px;
    margin: 3px 0;
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #007ace!important;
  }
  @media (max-width: 992px) {
    text-align: center;
    padding-bottom: 31px;
    .button-link {
      height: auto;
      text-align: left;
      margin-bottom: 6px;
    }
  }
}
