.search-result-faq {
  > .container {
    margin-bottom: 36px;

    h3 {
      margin-bottom: 24px;
    }

    .box {
      margin: 16px 0px;
      padding-bottom: 16px;
      border-bottom: 1px solid #e5e5e5;
      &-question {
        margin-bottom: 12px;

        font-weight: bold;
        font-size: 16px;
      }
    }
  }

  .markdown {
    display: inline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;

    /* headers */
    h1 {
      font-size: 18px;
    }
    h2 {
      font-size: 16px;
    }
    h1,
    h2 {
      font-weight: bold;
      margin: 8px 0px;
    }

    /* paragraph and font */
    p {
      margin: 16px 0px;
    }
    em {
      font-style: italic;
    }
    strong {
      font-weight: bold;
    }

    /* lists */
    ol,
    ul {
      margin: 16px 0px;
    }
    ol {
      list-style: decimal inside;
    }
    ul {
      list-style: circle inside;
    }
    li {
      ol,
      ul {
        margin: 0px 0px 0px 24px;
      }
    }
  }
}
