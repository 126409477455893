.search-result-title {
  font-size: 20px;
  padding: 0 0 25px 0;
  font-weight: 300;
  color: #212b36;
  border-bottom: 1px solid #e5e5e5;
  > h1 {
    line-height: 24px;
  }
}
