@import 'styles/base';

.navbar-fixed {
  width: 100%;
  max-width: 100%;
  position: fixed;
  min-height: 117px;
  background: color(white, base);
  box-shadow: 0 1px 0 0 #dfe3e8;
  z-index: 3;

  nav {
    padding: 0;
    align-items: flex-start;

    .logotipo-custom {
      margin: 38px 20px 35px 0;
      padding: 0;
    }
  }

  .toggle {
    border: none;
    position: absolute;
    top: 40px;
    right: 0;
  }
}
.navbar-custom {
  min-height: 100px;
  .link {
    font-size: 14px;
  }
  .hide {
    display: none;
  }
  .button-container {
    display: flex;
    margin: 40px 0 42px 0;
    button {
      margin: 0 5px;
      background-image: linear-gradient(to bottom, #eaa61e, #e6890e);
      &:first-child {
        background: transparent;
        border: 2px solid #f4a629;
        color: #f4a629 !important;
      }
    }
  }
  &-toggler {
    position: absolute;
    right: 5px;
    top: 30px;
    background-color: color(white, base) !important;
  }
}
@media (max-width: 992px) {
  .navbar-fixed {
    min-height: 100px;
  }
  .toggle {
    top: 35px !important;
  }
  .navbar-custom {
    .hide {
      display: block;
    }
    .logotipo-custom {
      margin-bottom: 5px !important;
      img {
        width: 208px;
        height: auto;
      }
    }
    .button-container {
      display: none;
    }
    &-collapse {
      background: color(white, base);
      border-radius: 3px;
      margin-top: 14px;
      z-index: 2;
    }
  }
}
