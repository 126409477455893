@import 'styles/base';
@import 'Navbar.module';

.layout {
  background-color: #fff;
  input,
  textarea {
    box-sizing: border-box;
  }
  button {
    cursor: pointer;
    height: 36px;
    color: #fff;
    font-size: 14px;
    padding: 0 19px;
    border-radius: 3px;
    font-family: 'Open Sans', sans-serif;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
  .body {
    padding-top: 118px;
  }
  .logo-header {
    width: 268px;
    height: 45px;
  }
  .footer {
    display: flex;
    font-size: font-size(fotter, base);
    align-items: center;
    padding: 68.1px 0 50.9px 0;
    border-top: 1px solid #e8f2f8;
    color: color(ink, lighter);
    a {
      margin: 5px 0;
      display: block;
      color: color(ink, lighter);
      cursor: pointer;
    }
    a[href] {
      text-decoration: none;
    }
    .logo {
      width: 30px;
    }
    .links {
      display: flex;
      align-items: center;
      a:nth-child(2) {
        margin-left: 28px;
      }
      a {
        margin: 0 17.5px;
      }
    }
    .hide-on-md {
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  .right {
    text-align: right;
  }
  @media (max-width: 992px) {
    .body {
      padding-top: 100px;
    }
    .footer {
      padding: 0;
      .links {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a {
          margin: 10px 0 !important;
        }
      }
    }
    .logo {
      margin: 29px 0 20px 0;
    }
    .right {
      margin: 22px 0 39px 0;
      font-weight: 100;
      text-align: left;
    }
  }
}
